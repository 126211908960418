import ChatService, {
  Conversation,
  Message,
} from "../../../services/chat.service";
import { UserState } from "../../../appState";
import Card from "../../../layout/Card";
import "../../../styles/chat.scss";
import { useEffect, useRef, useState } from "react";
import ConversationMessage from "./Message";
import Loader from "../Loader";
import { stat } from "fs";
import useWebSocketStore from "../../../services/webSocket.service";

const Conversations = () => {
  // Local State
  const [loading, setLoading] = useState(false);
  const [chatExpanded, setChatExpanded] = useState(false);
  const [chatComponentState, setChatComponentState] = useState<
    "conversations" | "conversation-detail" | "new-conversation"
  >("conversations");
  const [chatMessageInput, setChatMessageInput] = useState<string>("");
  // User State
  const user = UserState((state) => state);
  // Chat Service
  const createTwilioConversation = ChatService(
    (state) => state.createConversation
  );

  const scrollRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);

  // const [focusedConversation, setFocusedConversation] =
  //   useState<Conversation | null>(null);
  const chatConversations = ChatService((state) => state.conversations);
  const focusedConversation = ChatService((state) => state.focusedConversation);
  const setFocusedConversation = ChatService(
    (state) => state.setFocusedConversation
  );
  const createConversationMessage = ChatService(
    (state) => state.createConversationMessage
  );
  const setFocusedConversationMessages = ChatService(
    (state) => state.setFocusedConversationMessages
  );

  const getConversationMessages = ChatService(
    (state) => state.getConversationMessages
  );

  const addConversationParticipant = ChatService(
    (state) => state.addConversationParticipant
  );
  const getConversationParticipants = ChatService(
    (state) => state.getConversationParticipants
  );
  const setFocusedConversationParticipants = ChatService(
    (state) => state.setFocusedConversationParticipants
  );

  const setChatState = (
    state:
      | "conversations"
      | "conversation-detail"
      | "new-conversation"
      | "reset",
    options?: any
  ) => {
    switch (state) {
      case "conversations":
        setFocusedConversation(null);
        setChatComponentState(state);
        setLoading(false);
        break;
      case "conversation-detail":
        if (!options?.conversation) {
          return;
        }
        setFocusedConversation(options.conversation);
        setChatComponentState(state);
        break;
      case "new-conversation":
        setChatComponentState(state);
        break;
      case "reset":
        setFocusedConversation(null);
        setChatComponentState("conversations");
        return;
    }
  };

  useEffect(() => {
    if (chatComponentState === "conversations") {
    } else if (chatComponentState === "conversation-detail") {
      if (!!focusedConversation) {
        loadConversation(focusedConversation.conversationSid);
      }
    }
  }, [chatComponentState]);

  useEffect(() => {
    scrollChatToBottom();
  }, [focusedConversation?.messages]);

  const createNewChat = () => {
    // createTwilioConversation("New Convo", user?.uid || "").then((res) => {
    //   console.log(res);
    // });
    // addConversationParticipant(
    //   "CH1327e143f31c4048b86cb6bb7689bbf7",
    //   user?.uid || ""
    // );
  };

  const loadConversation = (conversationSid: string) => {
    setLoading(true);
    const convoReqs = [
      getConversationParticipants(conversationSid),
      getConversationMessages(conversationSid),
    ];
    Promise.all(convoReqs)
      .then((res) => {
        setFocusedConversationParticipants(res[0]);
        setFocusedConversationMessages(res[1]);
        setLoading(false);
        scrollChatToBottom(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleExpand = () => {
    setChatExpanded(!chatExpanded);
    const innerElement = chatRef.current;
    if (innerElement) {
      innerElement.style.height = chatExpanded ? "0px" : `500px`;
    }
  };

  const sendNewChatMessage = (event: any) => {
    event.preventDefault();
    if (!chatMessageInput) {
      return;
    }
    if (!!focusedConversation) {
      const newMessage = {
        author: user?.uid || "",
        body: chatMessageInput,
      };
      createConversationMessage(
        focusedConversation?.conversationSid,
        newMessage
      )
        .then((newMessage) => {
          setChatMessageInput("");
          const messages = focusedConversation?.messages || [];
          messages.push(newMessage);
          setFocusedConversationMessages(messages);
          const targets = focusedConversation?.participants?.filter(
            (p) => p.identity !== user?.uid
          );
          const ws = {
            message: newMessage,
            participants: targets,
          };
          useWebSocketStore.getState().emit("outgoing_message", ws);
          scrollChatToBottom();
        })
        .catch((err) => {});
    }
  };

  const scrollChatToBottom = (notSmooth: boolean = false) => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current?.scrollHeight,
          behavior: !notSmooth ? "smooth" : "auto",
        });
      }
    }, 100);
  };

  const getParticipantName = (identity: string) => {
    return (
      focusedConversation?.participants?.find((p) => p.identity === identity)
        ?.friendlyName || "Unknown"
    );
  };

  return (
    <div className="conversations-component">
      <div
        className="chat-open-tab"
        onClick={() => {
          handleExpand();
        }}
      >
        Chat
      </div>
      <div className="chat-content" ref={chatRef}>
        <div className="chat-heading">
          <h3>
            {
              {
                conversations: "Conversations",
                "conversation-detail":
                  focusedConversation?.friendlyName || "Conversation Detail",
                "new-conversation": "New Conversation",
              }[chatComponentState]
            }
          </h3>
          {chatComponentState !== "conversations" && (
            <button
              className="primary"
              onClick={() => {
                setChatState("reset");
              }}
            >
              X
            </button>
          )}
        </div>
        {/* <div className="section">
          <button
            className="primary fill"
            onClick={() => {
              createNewChat();
            }}
          >
            New Chat
          </button>
        </div> */}
        {/* CONVERSATIONS VIEW PANE */}
        {chatComponentState === "conversations" && !loading && (
          <div className="conversations">
            {!!chatConversations?.length &&
              chatConversations?.map((convo: Conversation) => {
                return (
                  <div
                    key={convo.conversationSid}
                    className="chat-conversation-tile"
                    onClick={() => {
                      setChatState("conversation-detail", {
                        conversation: convo,
                      });
                    }}
                  >
                    <Card key={convo.conversationSid} click="">
                      <h3>{convo.friendlyName}</h3>
                    </Card>
                  </div>
                );
              })}
            {!chatConversations?.length && (
              <div>
                No Conversations
                <button
                  className="primary fill"
                  onClick={() => {
                    createNewChat();
                  }}
                >
                  New Chat
                </button>
              </div>
            )}
          </div>
        )}
        {/* CONVERSATION DETAIL VIEW PANE */}
        {chatComponentState === "conversation-detail" && !loading && (
          <div className="section-content conversation-detail">
            <div className="chat-messages" ref={scrollRef}>
              {!!focusedConversation?.messages?.length &&
                !loading &&
                focusedConversation?.messages?.map((msg: Message) => {
                  return (
                    <ConversationMessage
                      key={msg.sid}
                      owned={msg.author === user?.uid}
                      dateStamp={
                        msg?.dateCreated
                          ? new Date(msg?.dateCreated)?.toLocaleTimeString(
                              "en-US"
                            )
                          : ""
                      }
                      body={msg.body}
                    ></ConversationMessage>
                  );
                })}
              {!focusedConversation?.messages?.length && !loading && (
                <p>No Messages in Conversation</p>
              )}
            </div>
            <div className="chat-message-input">
              <div className="row">
                <div className="col sm-col-4">
                  <input
                    type="text"
                    name="new-chat-message"
                    value={chatMessageInput}
                    onChange={(e) => {
                      setChatMessageInput(e.target.value);
                    }}
                    placeholder="New Message"
                  ></input>
                </div>
                <div className="col sm-col-2">
                  <button
                    className="primary outline"
                    onClick={(e) => {
                      sendNewChatMessage(e);
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {!!loading && (
          <div className="loading-overlay">
            <Loader></Loader>
          </div>
        )}
      </div>
    </div>
  );
};

export default Conversations;
