import "../../styles/login.scss";
import "../../styles/redeem-code.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  Auth,
  sendPasswordResetEmail,
} from "firebase/auth";

import logo from "../../assets/img/FFF_Logo_Orange_Horizontal.png";
import { useContext } from "react";
import { Context } from "../../context/AuthContext";
import { useEffect } from "react";

import GoogleIcon from "../../assets/img/google.png";
import AppleIcon from "../../assets/img/apple.png";
import { passwordRegex } from "../../data/constants";

import { io, Socket } from "socket.io-client";

/**
 * Login Page for the application
 */
const RedeemLogin = () => {
  const navigate = useNavigate();
  const user = useContext(Context);
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginSignUp, setLoginSignUp] = useState<"login" | "signup">("signup");
  const [forgotPasswordState, setForgotPassword] = useState(false);
  const [forgotResetSent, setForgotResetSent] = useState(false);
  const [showInvalidPassword, setShowInvalidPassword] = useState(false);

  /**
   * Handles basic Email/Password Login
   */
  const handleEmailLogin = async (e: any) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        console.log("User", user);
      })
      .catch((e) => {
        const errorCode = e.code;
        // Need to handle login errors w/ password
        if (errorCode === "auth/wrong-password") {
          setShowInvalidPassword(true);
        }
        console.error("Auth Error", e);
      });
  };

  const handleEmailSignup = async (e: any) => {
    e.preventDefault();
    setShowInvalidPassword(false);
    createUserWithEmailAndPassword(auth, email, password)
      .then((user) => {
        console.log("User", user);
      })
      .catch((e) => {
        console.error("Auth Error", e);
      });
  };

  /**
   * Handles Apple Login
   * @param e
   */
  const handleAppleLogin = (e: any) => {
    e.preventDefault();
    setShowInvalidPassword(false);
    const provider = new OAuthProvider("apple.com");
    signInWithPopupHandler(auth, provider);
  };

  /**
   * Handles Google Login
   * @param e
   */
  const handleGoogleLogin = (e: any) => {
    e.preventDefault();
    setShowInvalidPassword(false);
    const provider = new GoogleAuthProvider();
    signInWithPopupHandler(auth, provider);
  };

  /**
   * Handles Facebook Login
   * @param e
   */
  const handleFacebookLogin = (e: any) => {
    e.preventDefault();
    const provider = new FacebookAuthProvider();
    signInWithPopupHandler(auth, provider);
  };

  /**
   * Handles Forgot Password
   */
  const forgotPassword = () => {
    setShowInvalidPassword(false);
    setForgotPassword(true);
  };

  /**
   * Cancels Forgot Password
   */
  const cancelForgotPassword = () => {
    setForgotPassword(false);
  };

  /**
   * Sends the Password Reset Email
   */
  const sendPasswordReset = () => {
    sendPasswordResetEmail(auth, email)
      .then((res) => {
        setForgotResetSent(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  /**
   * Validate the Reset Password Form
   */
  const validateResetForm = () => {
    return !!email;
  };

  /**
   * Validate the Reset Password Form
   */
  const validateLoginSignUpForm = () => {
    if (!!email && !!password) {
      return true;
    }
  };

  /**
   * General Popup Sign in for third party providers
   * @param auth
   * @param provider
   */
  const signInWithPopupHandler = (auth: Auth, provider: any) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        let accessToken = null;

        // Set access tokens for vendor specific api access
        if (provider instanceof FacebookAuthProvider) {
          const credential = FacebookAuthProvider.credentialFromResult(result);
          accessToken = credential?.accessToken;
        } else if (provider instanceof GoogleAuthProvider) {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          accessToken = credential?.accessToken;
        } else if (
          provider instanceof OAuthProvider &&
          provider?.providerId === "apple.com"
        ) {
          // Add token items for Apple Login
        }

        // console.log("Provider Access Token", accessToken);

        if (!!user) {
          console.log("User", user);
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);
        // Console Error
        console.error(errorCode, errorMessage, email, credential);
      });
  };

  return (
    <div className="login-page">
      <div className="redeem-content" style={{maxWidth: '650rem', color: 'white'}}>
        <h2>Redeem your Discount Code now</h2>
        <p>After checkout, get access to the mobile app to start your 14 day FREE trial. Cancel anytime during your trial and you wont be charged.</p>
      </div>
      {/* Could split into componets here, works for now */}
      {forgotPasswordState ? (
        forgotResetSent ? (
          <div className="login-box">
            <h3>Reset Password</h3>
            <p>
              We have sent you an email with instructions to reset your
              password.
            </p>
            <div className="button-group full-width">
              <button
                className="primary fill hover-shadow"
                onClick={() => {
                  setForgotResetSent(false);
                  setForgotPassword(false);
                }}
              >
                Back to Login
              </button>
            </div>
          </div>
        ) : (
          <div className="login-box">
            <h3>Reset Password</h3>
            <p>
              Enter the email for your account, we will send you reset
              instructions.
            </p>
            <form className="forgot-password-form">
              <div className="input-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="email"
                  onChange={(e) => {
                    setEmail(e?.target?.value);
                  }}
                />
              </div>
            </form>
            <div className="button-group full-width">
              <button
                disabled={!validateResetForm()}
                className="primary fill hover-shadow"
                onClick={() => {
                  sendPasswordReset();
                }}
              >
                Send
              </button>
              <button
                className="primary outline hover-shadow"
                onClick={() => {
                  cancelForgotPassword();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )
      ) : loginSignUp === "signup" ? (
        <div className="login-box">
          <div className="login-logo">
            <img src={logo} alt="Fly Fish Finder Logo" />
          </div>
          <h3>Welcome!</h3>
          <form className="email-login">
            <div className="input-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                autoComplete="email"
                onChange={(e) => {
                  setEmail(e?.target?.value);
                }}
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e?.target?.value);
                }}
              />
            </div>
            <button
              disabled={!validateLoginSignUpForm()}
              className="primary fill hover-shadow"
              onClick={(e) => handleEmailSignup(e)}
            >
              Sign Up
            </button>
            <p
              tabIndex={0}
              className="link"
              onClick={() => {
                setLoginSignUp("login");
              }}
              onKeyUp={(e) => {
                e.key === "Enter" && setLoginSignUp("login");
              }}
            >
              Have an account? Sign in.
            </p>
          </form>
        </div>
      ) : (
        <div className="login-box">
          <div className="login-logo">
            <img src={logo} alt="Fly Fish Finder Logo" />
          </div>
          <h3>Welcome Back!</h3>

          <form className="email-login">
            <div className="input-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e?.target?.value);
                }}
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e?.target?.value);
                }}
              />
              {showInvalidPassword && (
                <p className="input-error">Invalid Password</p>
              )}
            </div>
            <p
              tabIndex={0}
              className="link"
              onClick={() => {
                forgotPassword();
              }}
              onKeyUp={(e) => {
                e.key === "Enter" && forgotPassword();
              }}
            >
              Forgot Password?
            </p>
            <button
              disabled={!validateLoginSignUpForm()}
              className="primary fill hover-shadow"
              onClick={(e) => handleEmailLogin(e)}
            >
              Login
            </button>
            <p
              tabIndex={0}
              className="link"
              onClick={() => {
                setLoginSignUp("signup");
              }}
              onKeyUp={(e) => {
                e.key === "Enter" && setLoginSignUp("signup");
              }}
            >
              Don't have an account? Sign up.
            </p>
          </form>
          <div className="divider">
            <span className="divider-text" style={{ fontSize: "0.8em" }}>
              OR
            </span>
          </div>
          {/* Need to add iconography - could go FontAwesome free here */}
          <div className="social-logins">
            <button
              className="generic full-round hover-shadow"
              onClick={(e) => handleAppleLogin(e)}
            >
              <img src={AppleIcon} alt="Apple Icon" />
              Continue with Apple
            </button>
            <button
              className="generic full-round hover-shadow"
              onClick={(e) => handleGoogleLogin(e)}
            >
              <img src={GoogleIcon} alt="Google Icon" />
              Continue with Google
            </button>
            {/* <button className="primary outline hover-shadow" onClick={(e) => handleFacebookLogin(e)}>Continue with Facebook</button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default RedeemLogin;
