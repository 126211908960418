import { useNotification } from "../../context/NotificationsContext";
import "../../styles/notifications.scss";

const Notification: React.FC = () => {
  const { notifications } = useNotification();

  return (
    <div className="notification-container">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className={`notification notification-${notification?.type}`}
        >
          {notification.message}
        </div>
      ))}
    </div>
  );
};

export default Notification;
