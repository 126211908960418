import { auth } from "../config/firebase";
import { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "@firebase/auth";
import { UserContext } from "../models/FirebaseUser";
import { UserState } from "../appState";
import { db } from "../config/firebase";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import { User } from "../models/User";
import PageLoader from "../components/controls/PageLoader";
import ChatService, {
  Conversation,
  Participant,
} from "../services/chat.service";

export const Context = createContext<UserContext | null>(null);

const AuthProvider = (props: any) => {
  // Set Local User State
  const [user, setUser] = useState<any>(null);
  // Set Local Application Loading State
  const [appLoading, setAppLoading] = useState<boolean>(true);
  const storeUser = UserState((state) => state);
  const setStoreUser = UserState((state) => state.setUser);
  const setFavoriteMarkers = UserState((state) => state.setFavoriteMarkers);
  const favoriteMarkers = UserState((state) => state.favoriteMarkers);
  const getTwilioUser = ChatService((state) => state.getUser);
  const setTwilioToken = ChatService((state) => state.setUserToken);
  const getAllUserConversations = ChatService(
    (state) => state.getAllUserConversations
  );
  const setConversations = ChatService((state) => state.setConversations);
  const getConversationParticipants = ChatService(
    (state) => state.getConversationParticipants
  );

  useEffect(() => {
    let unsubscribe = onAuthStateChanged(auth, (user) => {
      // Have our Auth state chage back, stop loading
      // Check user, set if present
      if (!!user) {
        setUser(user);
        loadUser(user);
      } else {
        setUser(null);
        setAppLoading(false);
      }
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const loadUser = async (user: any) => {
    if (!user?.uid) return;
    const docRef = doc(db, "users", user.uid);
    getDoc(docRef)
      .then((res) => {
        const data = res?.data();
        const profile = new User(data);
        setStoreUser(profile);
        console.log("User Loaded", storeUser);
        // Get Twilio User
        getTwilioUser(user?.uid)
          .then((res) => {
            console.log("Twilio USer", res);
            setTwilioToken(res?.identity);
            // Conversations
            getAllUserConversations(user?.uid).then((res) => {
              let conversations = res || [];
              conversations?.forEach((conversation: Conversation) => {
                getConversationParticipants(conversation.conversationSid).then(
                  (participants) => {
                    conversation.participants = participants;
                    if (conversation?.participants?.length === 2) {
                      conversation.friendlyName =
                        conversation?.participants?.find(
                          (p: Participant) => p.identity !== user?.uid
                        )?.friendlyName || "Unknown User";
                    }
                  }
                );
              });
              setConversations(res);
            });
          })
          .catch((err) => {
            console.log("Twilio Error", err);
          });
        // Gather User Data

        // Favorite Markers
        setAppLoading(false);
      })
      .catch((err) => {
        const userRef = doc(db, "users", user.uid);
        // TODO, need to instantiate a full new User Object
        setDoc(userRef, {
          uid: user.uid,
          email: user?.email,
          cover_photo: user?.photoURL,
        }).then(() => {
          loadUser(user.uid);
          setAppLoading(false);
        });
      });
  };

  return (
    <Context.Provider value={{ user, setUser }}>
      {!appLoading ? props?.children : <PageLoader></PageLoader>}
    </Context.Provider>
  );
};

export default AuthProvider;
