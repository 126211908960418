import "../styles/card.scss";

const Card = (props: any) => {
  const [cardOptions] = [props.cardOptions];
  const width = cardOptions?.width || "fit";
  const disabled = cardOptions?.disabled || false;

  return (
    <div
      className={`card ${width}${disabled ? " disabled" : ""}${
        !!cardOptions?.onClick ? " clickable" : ""
      }`}
      onClick={() => {
        if (disabled) {
          return;
        }
        if (cardOptions?.onClick) {
          cardOptions?.onClick();
        }
      }}
    >
      {props?.children}
      {!!props?.cardOptions?.disabled && (
        <div className="card-disabled">
          <p>
            {props?.cardOptions?.disabledMessage
              ? props?.cardOptions?.disabledMessage
              : "Unavailable"}
          </p>
        </div>
      )}
    </div>
  );
};

export default Card;
