import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Maps from "./views/Maps";
import Courses from "./views/Courses";
import Login from "./views/Login";
import { Fragment, useEffect } from "react";
import Navigation from "./layout/Navigation";
import { useContext } from "react";
import { Context } from "./context/AuthContext";
import Subscribe from "./views/Subscribe";
import useWebSocketStore from "./services/webSocket.service";
import { Conversation } from "./services/chat.service";
import Conversations from "./components/controls/Conversations/Conversations";
import CheckoutSuccess from "./views/CheckoutSuccess";
import RedeemCode from "./views/redeemFlow/RedeemCode";
import { AppState } from "./appState";

const AppRouter = () => {
  const user = useContext(Context);
  const connect = useWebSocketStore((state: any) => state.connect);

  const showNavigation = AppState((state) => state.showNavigation);
  const showConversations = AppState((state) => state.showConversations);

  //const connect = useWebSocketStore.getState().connect;
  let socket: any;

  useEffect(() => {
    if (!socket) {
      user?.user?.getIdToken().then((result) => {
        localStorage.setItem("ffftoken", result);
        localStorage.setItem("fffuser", user?.user?.uid);
        socket = connect(result, user?.user?.uid);
      });
    }
    return () => {
      socket?.disconnect();
    };
  }, [connect, user?.user]);

  return (
    <Router>
      <Fragment>
        {!!user?.user && !!showNavigation && <Navigation />}
        {!!user?.user && !!showConversations && <Conversations />}
        <Routes>
          {/* <Route path="/subscribe" element={<Subscribe />} /> */}
          {/* <Route path="/courses" element={<Courses />} /> */}
          {/* <Route path="/maps" element={<Maps />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/redeem-code" element={<RedeemCode />} />
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="*" element={<Navigate replace to="/redeem-code" />} />
        </Routes>
      </Fragment>
    </Router>
  );
};

export default AppRouter;
