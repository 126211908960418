
const baseMapURL = `${process.env.REACT_APP_FFF_MAP_API_URL}`;

console.log("baseMapURL", baseMapURL);

const streamTileUrl =
  `${baseMapURL}/cache/fff_nhd_streams/tiles/{z}/{x}/{y}.pbf`
const usPublicLandsURL =
  `${baseMapURL}/cache/public_lands`;
const usgsTileUrl = `${baseMapURL}/tiles/public.usgs_terrestrial_trails/{z}/{x}/{y}.pbf`;

export class MapBoxLayer {
  layerId: string;
  type: string;
  sourceType: string;
  tileUrl: string;
  sourceId: string;
  sourceMaxZoom: number;
  belowLayerID: string;
  style: any = {
    rasterOpacity: 1,
  };
  toggle: boolean;
  onBasic: boolean;
  mapStyles: string[];
  hidePopup: boolean;
  layerType: string;
  layout: any;

  constructor(data: Partial<MapBoxLayer> = {}) {
    this.layerId = data?.layerId || "";
    this.type = data?.type || "";
    this.sourceType = data?.sourceType || "";
    this.tileUrl = data?.tileUrl || "";
    this.sourceId = data?.sourceId || "";
    this.sourceMaxZoom = data?.sourceMaxZoom || 14;
    this.belowLayerID = data?.belowLayerID || "";
    this.style = data?.style || {
      rasterOpacity: 1,
    };
    this.toggle = data?.toggle || false;
    this.onBasic = data?.onBasic || false;
    this.mapStyles = data?.mapStyles || [];
    this.hidePopup = data?.hidePopup || false;
    this.layerType = data?.type || "";
    this.layout = data?.layout || {};
  }
}

export const layers = [
  // {
  // 	layerId: "esri-ortho",
  // 	type: "raster",
  // 	sourceType: "raster",
  // 	tileUrl:
  // 		"https://ibasemaps-api.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?token=AAPK8670dc828e444b2fa47905b10052207e2iaeQB5MfUJThtMi7M-wm8lEOF5J1km3NfixyOkEzs-l75wXlIQVyMrmGxVuDgiH",
  // 	sourceId: "esri-ortho-source",
  // 	belowLayerID: "waterway-label",
  // 	style: {
  // 		rasterOpacity: 1,
  // 	},
  // 	toggle: false,
  // 	onBasic: true,
  // 	mapStyles: ["basic", "pro", "pro-picks"],
  // 	hidePopup: true,
  // 	layerType: "data"
  // },
  {
    text: "US Streams",
    layerId: "us_streams",
    type: "line",
    sourceId: "us_streams_source",
    tileUrl: streamTileUrl,
    sourceLayerID: "fff_nhd_streams",
    belowLayerID: "waterway",
    style: {
      lineColor: "#3fbe37",
      lineWidth: 2.75,
      // lineOpacity:
      //   zoomLevel >= 15 ? 0.2 : baseLayer === 'Satellite' ? 0.7 : 1.0, //move this logic somewhere else
    },
    filter: [
      "all",
      ["!", ["has", "is_ditch"]],
      ["==", ["get", "is_trout"], 0],
    ],
    onBasic: true,
    mapStyles: ["basic", "pro", "pro-picks"],
    img: require("../../assets/img/trout_stream.png"),
    toggle: true,
    layerType: "stream"
  },
  {
    text: "Trout Streams",
    layerId: "trout_streams",
    type: "line",
    sourceId: "trout_streams_source",
    tileUrl: streamTileUrl,
    sourceLayerID: "fff_nhd_streams",
    belowLayerID: "waterway",
    style: {
      lineColor: "#1055e3",
      lineWidth: 2.75,
      // lineOpacity:
      //   zoomLevel >= 15 ? 0.2 : baseLayer === 'Satellite' ? 0.7 : 1.0, //move this logic somewhere else
    },
    filter: [
      "all",
      ["!", ["has", "is_ditch"]],
      ["==", ["get", "is_trout"], 1],
    ],
    onBasic: true,
    mapStyles: ["basic", "pro", "pro-picks"],
    img: require("../../assets/img/other_river.png"),
    toggle: true,
    layerType: "stream"
  },
  {
    text: "Bridge Access",
    layerId: "fffBridgePoints",
    type: "circle",
    sourceId: "composite",
    visible: false,
    onBasic: true,
    mapStyles: ["basic", "pro", "pro-picks"],
    style: {
      // circleColor: 'red',
    },
    img: require("../../assets/layerImg/bridge_points.png"),
    toggle: true,
    layerType: "data"
  },
  {
    text: "Designated Trout Water",
    layerId: "designated-trout-water",
    sourceId: "composite",
    belowLayerID: "waterway",
    style: {
      lineColor: "#000000",
    },
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/wavyBlack.png"),
    type: "line",
    toggle: true,
    layerType: "stream"
  },
  {
    text: "Premium Access",
    layerId: "fffPremiumAccess",
    sourceId: "composite",
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/dnr_access_point.png"),
    type: "symbol",
    toggle: true,
    layerType: "data"
  },

  {
    text: "Trail Access",
    layerId: "fffTrailPoints",
    sourceId: "composite",
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/trail_access_point.png"),
    type: "symbol",
    toggle: true,
    layerType: "data"
  },
  {
    text: "Boat Ramps",
    layerId: "fffBoatramps",
    sourceId: "composite",
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/boat_launch_new2.png"),
    type: "symbol",
    toggle: true,
    layerType: "data"
  },
  {
    text: "Gold Medal/Blue Ribbon",
    layerId: "fffGoldStreams",
    sourceId: "composite",
    style: {
      lineColor: "gold",
      lineOpacity: 0.8,
    },
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/gold_streams.png"),
    type: "line",
    toggle: true,
    layerType: "stream"
  },
  {
    text: "Wild Trout",
    layerId: "wild_trout",
    sourceId: "wild_trout_source",
    tileUrl: streamTileUrl,
    sourceLayerID: "fff_nhd_streams",
    style: {
      lineColor: [
        "case",
        ["==", ["get", "wild_trout"], 1],
        "#e51528",
        ["==", ["get", "is_trout"], 1],
        "#3dbd35",
        "#5b86db",
      ],
      lineWidth: 6,
    },
    filter: ["==", ["get", "wild_trout"], 1],
    belowLayerID: "fffBridgePoints",
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/wild_trout.png"),
    type: "line",
    toggle: true,
    layerType: "stream"
  },
  {
    text: "Easements",
    layerId: "fffEasements",
    sourceId: "composite",
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/Easement.png"),
    type: "fill",
    toggle: true,
    layerType: "data"
  },
  {
    text: "Trails",
    layerId: "fff_trails",
    sourceId: "fff_trails_source",
    tileUrl: usgsTileUrl,
    sourceLayerID: "public.usgs_terrestrial_trails",
    belowLayerID: "fffTrailPoints",
    style: {
      lineColor: "#CD5C5C",
      lineWidth: 2,
      lineDasharray: [1, 0],
    },
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/fff_hiking.png"),
    type: "line",
    toggle: true,
    layerType: "data"
  },
  {
    text: "Public Lands",
    layerId: "public_lands",
    sourceId: "public_lands_source", //MALCOLM should we make everything with an "id" be ID - so sourceID?, layerID, etc.?
    sourceLayerID: "public_lands",
    tileUrl: usPublicLandsURL,
    style: {
      fillColor: [
        "match",
        ["get", "land_class"],
        "National Forest",
        "#d46060",
        "State Other",
        "#81c447",
        "State Park",
        "#5a98d1",
        "Federal Conservation or Resource Management",
        "#edcf72",
        "American Indian Areas",
        "#dd78aa",
        "Federal Other",
        "#a5dde9",
        "Non-Governmental Organization",
        "#c7aa52",
        "Local Parks and Recreation",
        "#7e4747",
        "National Park Service",
        "#6aa84f",
        "hsl(33, 53%, 64%)",
      ],
      fillOpacity: 0.8,
    },
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/fff_publiclands.png"),
    type: "fill",
    toggle: true,
    layerType: "data"
  },
  {
    text: "Stream Gages",
    layerId: "stream-gages-active-0zf66t",
    sourceId: "composite",
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/stream_gage.png"),
    type: "symbol",
    toggle: true,
    layerType: "data"
  },
  {
    text: "Fly Shops & Retailers",
    layerId: "flyshops",
    sourceId: "flyshops_source",
    tileUrl:
      `${baseMapURL}/tiles/public.fff_flyshops/{z}/{x}/{y}.pbf`,
    sourceLayerID: "public.fff_flyshops",
    layout: {
      "symbol-avoid-edges": true,
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "icon-image": "Business",
      "icon-size": 1,
    },
    visible: false,
    onBasic: true,
    mapStyles: ["basic", "pro", "pro-picks"],
    img: require("../../assets/layerImg/Business.png"),
    type: "symbol",
    toggle: true,
    layerType: "data"
  },
  {
    text: "Trailheads",
    layerId: "fffTrailheads",
    sourceId: "composite",
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/trailheadv2.png"),
    type: "symbol",
    toggle: true,
    layerType: "data"
  },
  {
    text: "Parking",
    layerId: "fffParking",
    sourceId: "composite",
    visible: false,
    onBasic: false,
    mapStyles: ["pro", "pro-picks"],
    img: require("../../assets/layerImg/parkingv1.png"),
    type: "symbol",
    toggle: true,
    layerType: "data"
  },
];
