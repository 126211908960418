import { useContext, useEffect, useState } from "react";
import "../../styles/login.scss";
import "../../styles/redeem-code.scss";
import { Context } from "../../context/AuthContext";
import RedeemLogin from "./Redeem.Login";
import RedeemPayment from "./Redeem.Payment";
import RedeemConfirm from "./Redeem.Confirm";
import FFFAPI from "../../services/fffAPI.service";
import Loader from "../../components/controls/Loader";
import { AppState } from "../../appState";

const RedeemCode = () => {
    const toggleNavigation = AppState((state) => state.toggleNavigation);
    const user = useContext(Context);
    const [paymentSucess, setPaymentSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        toggleNavigation(false);

        // If we have a user logged in, we can move to the next step in flow
        const urlParams = new URLSearchParams(window.location.search);
        const success = urlParams.get('success');
        const sessionId = urlParams.get('session_id');
        setLoading(true);
        if (!!success && !!sessionId) {
            // FFFAPI.getCheckoutSession(sessionId).then((res) => {
            //     const data = res.data;
            //     // Build Affilitate Object
            //     const order = new AOrder({
            //         order_id: data.subscription?.id,
            //         amount: `${data.subscription?.plan?.amount / 100}`,
            //     });
            //     const item = 
            //         new AItem({
            //             order_id: data.subscription?.id,
            //             parent_sku: data.subscription?.plan?.id,
            //             price: `${data.subscription?.plan?.amount / 100}`,
            //             qty: '1',
            //         })
            //     const script = document.createElement('script');
            //     script.text = `
            //       var _AvantMetrics = _AvantMetrics || [];
            //       _AvantMetrics.push(['order', ${JSON.stringify(order)}]);
            //       _AvantMetrics.push(['item', ${JSON.stringify(item)}]);
            //       AvantMetricsFunction();
            //     `;
            //     document.head.appendChild(script);

            //     setPaymentSuccess(true);
            //     setLoading(false);
                
            // }).catch((err) => {
            //     console.log(err);
            //     setLoading(false);
            // }
            // );
            setLoading(false);
            setPaymentSuccess(true);
          } else {
            setLoading(false);
          }
      }, []);

  return (
    <div id="redeem-code" className="redeem-code">
      {loading ? (
        <Loader loaderText="Retrieving Data"/>
      ) : !user?.user ? (
        <RedeemLogin />
      ) : !paymentSucess ? (
        <RedeemPayment />
      ) : (
        <RedeemConfirm />
)}
    </div>
  );
};

export default RedeemCode;

class AOrder {
  order_id: string;
  amount: string;
  currency: string;
  state?: string;
  country: string;
  new_customer?: boolean;
  ecc?: string;

  constructor(data: Partial<AOrder> = {}) {
      
    this.order_id = data?.order_id || '';
    this.amount = data?.amount || '0';
    this.currency = data?.currency || 'USD';
    this.state = data?.state || '';
    this.country = data?.country || '';
    this.new_customer = data?.new_customer || false;
    this.ecc = data?.ecc || '';
  }
}

class AItem {
  order_id: string;
  parent_sku: string;
  variant_sku?: string;
  price: string;
  qty: string;

  constructor(data: Partial<AItem> = {}) {
    this.order_id = data?.order_id || '';
    this.parent_sku = data?.parent_sku || '';
    this.variant_sku = data?.variant_sku || '';
    this.price = data?.price || '0';
    this.qty = data?.qty || '0';
  }
}