import { signOut, getAuth } from "@firebase/auth";
import { NavLink, useNavigate } from "react-router-dom";
import logoWhite from "../assets/img/logoWhite.svg";
import DashboardIcon from "../assets/icons/DashboardIcon";
import Map from "../assets/icons/Map";
import CoursesIcon from "../assets/icons/CoursesIcon";
import LogOutIcon from "../assets/icons/LogOutIcon";
import BadgeCheck from "../assets/icons/BadgeCheck";

/**
 * Initial Nav - Will be transitioning styles over, just a prototype to get the flow going
 * @param props
 * @returns
 */
const Navigation = (props: any) => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate("/login");
    } catch (e) {
      console.error(e);
    }
  };

  const goToPremium = async () => {
    navigate("/subscribe");
  };

  // Initial Test Nav - will get iconography and styles rebuilt/migrated
  return (
    <div className="main-navigation">
      <nav>
        <div id="app-logo">
          <img
            src={logoWhite}
            alt="fly fish finder logo"
            className="sidebar-icon"
          />
        </div>
        <div className="nav-links">
          <ul id="links-ul">
            <li>
              <NavLink
                to={"/"}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <div className="icon-area">
                  <div className="icon">
                    <DashboardIcon />
                  </div>
                </div>
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/maps"}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <div className="icon-area">
                  <div className="icon">
                    <Map />
                  </div>
                </div>
                <p>Map</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/courses"}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <div className="icon-area">
                  <div className="icon">
                    <CoursesIcon />
                  </div>
                </div>
                <p>Courses</p>
              </NavLink>
            </li>
            {/* Feature to come */}
            {/* <li>
            <Link to={'/anglers'}> 
              Anglers
            </Link>
          </li> */}
            {/* Feature to come */}
            {/* <li>
            <Link to={'/settings'}> 
              Settings
            </Link>
          </li> */}
          </ul>
          <ul id="signout-ul">
            <li>
              <button
                onClick={() => {
                  goToPremium();
                }}
              >
                <div className="icon-area">
                  <div className="icon">
                    <BadgeCheck />
                  </div>
                </div>
                <p>Get Premium</p>
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  handleSignOut();
                }}
              >
                <div className="icon-area">
                  <div className="icon">
                    <LogOutIcon />
                  </div>
                </div>
                <p>Sign Out</p>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
