import "./App.scss";
import AppRouter from "./Router";
import Conversations from "./components/controls/Conversations/Conversations";
import Notification from "./components/controls/Notification";
import AuthProvider from "./context/AuthContext";
import { NotificationProvider } from "./context/NotificationsContext";

function App() {
  return (
    <div className="FlyFishFinderApp">
      <AuthProvider>
        <NotificationProvider>
          <AppRouter />
          {/* <Conversations></Conversations> */}
          <Notification />
        </NotificationProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
