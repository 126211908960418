import io, { Socket } from "socket.io-client";
import { create } from "zustand";
import ChatService from "./chat.service";
import { getAddNotification } from "../context/NotificationsContext";

export interface WebSocketState {
  isConnected: boolean;
  messages: string[];
  socket?: Socket;
  connect: (token: string, uid: string) => Socket;
  emit: (event: string, data: any) => void;
}

const useWebSocketStore = create<WebSocketState>((set) => ({
  isConnected: false,
  messages: [],
  socket: undefined,
  connect: (token: string, uid: string) => {
    const socket = io(`${process.env.REACT_APP_API_URL}`, {
      closeOnBeforeunload: false,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
      query: { uid: uid },
    });

    socket.on("connect", () => {
      set({ isConnected: true, socket });
    });

    socket.on("incoming_message", (msg: any) => {
      const focusedConversation = ChatService.getState().focusedConversation;
      if (
        !focusedConversation?.conversationSid ||
        focusedConversation?.conversationSid !== msg?.conversationSid
      ) {
        const addNotification = getAddNotification();
        addNotification(`${msg?.body}`, "success");
      } else {
        const messages = focusedConversation?.messages || [];
        messages.push(msg);
        ChatService.getState().setFocusedConversationMessages(messages);
      }
    });

    return socket;
    // Handle other socket events...
  },
  emit: (event: string, data: any) => {
    set((state) => {
      if (state.socket) {
        state.socket.emit(event, data);
      }
      return state;
    });
  },
}));

export default useWebSocketStore;
