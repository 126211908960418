import { useEffect, useState } from "react";
import "../../styles/login.scss";
import "../../styles/redeem-code.scss";
import { UserState } from "../../appState";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Check, PinDrop, Groups, Hiking } from "@mui/icons-material";

const orderApiUrl = `${process.env.REACT_APP_API_URL}/api/orders`;

const RedeemPayment = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState<string>("Pro Nationwide");
  const [errorMsg, setErrorMsg] = useState<string>("");

  useEffect(() => {
    // Capture Affiliate ID from URL
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateId = urlParams.get('avad');
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
  
    console.log(affiliateId);
    console.log(futureDate);

  }, []);

  const redeemPromoCode = async() => {
    setErrorMsg("");
    const promoCode = (document.getElementById('promo-code-input') as HTMLInputElement).value;
    if (!promoCode) {
      setErrorMsg("Please enter a promo code");
      return;
    }
    const checkoutSession: any = await getPromoCode(promoCode);
    if (!!checkoutSession.error) {
      return;
    } else if (!!checkoutSession.data) {
      const checkoutUrl = checkoutSession?.data;
      window.location.href = checkoutUrl; 
      return;
    }
  };

  const getPromoCode = async (promoCode: string) => {
    let priceId = "";
    if (product === "Pro 2 States") {
      priceId = "price_1QajojGL1dDBQUpkOigbhTmF";
    } else if (product === "Pro Nationwide") {
      priceId = "price_1QajpXGL1dDBQUpkYNpXIcNR";
    } else if (product === "Elite") {
      priceId = "price_1QajpuGL1dDBQUpkpIyfQyJP";
    }
    return new Promise(async (resolve, reject) => {
      fetch(`${orderApiUrl}/createCheckoutSession`, {
        method: "POST",
        headers: getOrdersHeaders(),
        body: JSON.stringify({
          "promoCode": promoCode,
          "priceId": priceId,
        }),
      }).then((res) => {
        if (res.status === 200) {
          return resolve(res.json());
        } else if (res.status === 500) {
          setErrorMsg("Invalid Promo Code, Try Again");
        }
      }).catch((err) => {
        setErrorMsg(err.message);
        console.log(err);
      });
    });
  }

  function getOrdersHeaders() {
    const UserToken = localStorage.getItem("ffftoken");
    const UID = UserState.getState().uid || "";
    return new Headers({
      "content-type": "application/json",
      "authorization": `${UserToken}`,
      "x-user-id": UID,
    });
  }

    const handleSignOut = async () => {
      const auth = getAuth();
      try {
        await signOut(auth);
        navigate("/");
      } catch (e) {
        console.error(e);
      }
    };

  return (
    <div id="redeem-payment">
      <div className="redeem-steps">
        <div className="step">
          <p className="step-num complete"><Check/></p>
          <h3 className="brand-color-1">Sign Up</h3>
        </div>
        <div className="step-div complete"></div>
        <div className="step">
          <p className="step-num current">2</p>
          <h3>Claim Discount</h3>
        </div>
        <div className="step-div"></div>
        <div className="step">
          <p className="step-num">3</p>
          <h3>Get App</h3>
        </div>
      </div>
      <div id="discount-redeem-form">
        <div id="discount-redeem-text">
          <h2>Claim your Discount Code now.</h2>
          <p>Choose your preferred premium plan, enter your discount code, checkout and instantly access up to 225k+ Rivers and Streams. Enjoy your <b>14 day FREE trial</b>, cancel your trial anytime and you won't be charged.</p>
        </div>
        <div id="discount-redeem-input">
          <h2>Choose your Plan</h2>
          <div className="product-select">
            <div className={`product-option ${product === "Pro 2 States" && "active"}`} onClick={() => {
              setProduct("Pro 2 States");
            }} >
              <div className="active-marker">
                <Check/>
              </div>
              <div className="product-option-text">
                <div className="product-name">
                  <h3>Pro 2 State</h3>
                  <p>($29.99/yr)</p>
                </div>
                <div className="product-features">
                  <ul>
                    <li>Access Maps across 2 States</li>
                    <li>Get involved with the Community</li>
                  </ul>
                </div>
              </div>
            </div>
            <div 
            className={`product-option ${product === "Pro Nationwide" && "active"}`}
            onClick={() => {
              setProduct("Pro Nationwide");
            }}>
              <div className="active-marker">
                  <Check/>
                </div>
                <div className="product-option-text">
                  <div className="product-name">
                    <h3>Pro Nationwide</h3>
                    <p>($49.99/yr)</p>
                  </div>
                  <div className="product-features">
                    <ul>
                      <li>Access Maps Nationwide</li>
                      <li>Get involved with the Community</li>
                      <li>Connect with Guides</li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
          <div className="code-redeem">
            <div className="input-section">
              <input id="promo-code-input" name="promo-code" type="text" placeholder="Claim Code" />
              { !!errorMsg && <p className="error">{errorMsg}</p> }
            </div>
            <button className="primary outline" onClick={() => {
              redeemPromoCode();
            }}>Redeem</button>
          </div>
        </div>
      </div>
      <div id="discount-redeem-feature-boxes">
        <div className="feature">
          <PinDrop/>
          <h4>Maps & Filters</h4>
          <p>As an angler you're not limited to one fish, so you're app shouldn't be either. Find the best rivers and lakes with ease with 225k+ Rivers and Streams mapped for over 100 fish species. Over a million additional points of interest have been mapped including stream gauges, fly shops, bridge access and more.</p>
        </div>
        <div className="feature">
          <Groups/>
          <h4>Get involved with the Community</h4>
          <p>Join the first app of it's kind built for fly fishing community. Discover anglers across the country, plan trips and catch more fish all for less!</p>
        </div>
        <div className="feature">
          <Hiking/>
          <h4>Connect with Guides</h4>
          <p>Completely DIY apps are bad for the backbone of the fishing industry, America's fly fishing Guides & Shops. That's why we're building game changing features that make it possible (and affordable) to take more trips and explore a ton of new water faster than ever before.</p>
        </div>
      </div>
      <div id="discount-redeem-feature-video">
        <div className="video-text">
          <h2>The Ultimate Fly Fishing Tool Built For Adventure.</h2>
          <p>From scouting water with the most detailed fly fishing maps on the market, to building your fishing network so you can take more trips, our tools will help get you there.</p>
        </div>
        <div className="video">
        <iframe id="fff-video" src="https://www.youtube.com/embed/jZAt9s-51aY?si=j-2eTgfaEAvxzjKY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
      </div>
      <div id="payment-logout">
          <button className="primary outline" onClick={() => {
            handleSignOut()
          }}>Log Out</button>
      </div>
    </div>
  );
};

export default RedeemPayment;

const getCookie = (key: any) => {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}