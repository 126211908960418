import { useEffect } from "react";
import "../../styles/login.scss";
import "../../styles/redeem-code.scss";
import { Check } from "@mui/icons-material";

const RedeemConfirm = () => {
  return (
    <div id="redeem-confirm" className="redeem-confirm">
      <div className="redeem-steps">
        <div className="step">
          <p className="step-num complete"><Check/></p>
          <h3 className="brand-color-1">Sign Up</h3>
        </div>
        <div className="step-div complete"></div>
        <div className="step">
          <p className="step-num complete"><Check/></p>
          <h3 className="brand-color-1">Claim Discount</h3>
        </div>
        <div className="step-div complete"></div>
        <div className="step">
          <p className="step-num current">3</p>
          <h3>Get App</h3>
        </div>
      </div>
      <div className="redeem-confirm-text">
        <h2 className="brand-color-1">Your discount has been applied, download the app to get started!</h2>
        <p>Cancel anytime prior and you wont be charged.</p>
      </div>
      <div className="app-download">
        <a href="https://apps.apple.com/us/app/flyfishfinder-fly-fishing-hub/id6446231902" target="_blank" rel="noreferrer">
          <img height="60" src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png" alt="Get it on Google Play" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.flyfishfinderappv2" target="_blank" rel="noreferrer">
          <img height="60" src="https://lh3.googleusercontent.com/cjsqrWQKJQp9RFO7-hJ9AfpKzbUb_Y84vXfjlP0iRHBvladwAfXih984olktDhPnFqyZ0nu9A5jvFwOEQPXzv7hr3ce3QVsLN8kQ2Ao=s0" alt="Get it on Google Play" />
        </a>
      </div>
    </div>
  );
};

export default RedeemConfirm;
