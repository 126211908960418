export class User {
  uid?: string;
  name?: string;
  email?: string;
  cover_photo?: string;
  favoriteMarkers?: FavoriteMarker[];
  savedStreams?: Stream[];
  subscriptionInfo?: SubscriptionInfo;
  stripeCustomerID?: string;

  constructor(data: Partial<User> = {}) {
    this.uid = data?.uid || "";
    this.name = data?.name || "";
    this.email = data?.email || "";
    this.cover_photo = data?.cover_photo || "";
    this.favoriteMarkers =
      data?.favoriteMarkers?.map((fm) => new FavoriteMarker(fm)) || [];
    this.savedStreams = data?.savedStreams?.map((ss) => new Stream(ss)) || [];
    this.subscriptionInfo = data?.subscriptionInfo
      ? new SubscriptionInfo(data?.subscriptionInfo)
      : undefined;
    this.stripeCustomerID = data?.stripeCustomerID || "";
  }
}

// Move to Maps Classing
export class FavoriteMarker {
  coordinates?: string[];
  markerName?: string;
  markerType?: string;
  markerColor?: string;
  imageUrls?: string[];
  notes?: string;
  publicView?: boolean;
  uid?: string;

  constructor(data: Partial<FavoriteMarker> = {}) {
    this.coordinates = data?.coordinates || [];
    this.markerName = data?.markerName || "";
    this.markerType = data?.markerType || "Information";
    this.markerColor = data?.markerColor || "#ff0000";
    this.imageUrls = data?.imageUrls || [];
    this.notes = data?.notes || "";
    this.publicView = data?.publicView || false;
    this.uid = data?.uid || "";
  }
}

// Move to Maps Classing
export class Stream {
  streamName?: string;
  streamType?: "River" | "Creek" | "Other";
  constructor(data: Partial<Stream> = {}) {
    this.streamName = data.streamName;
    this.streamType = data.streamType;
  }
}

export class SubscriptionInfo {
  paymentStatus?: "Premium" | "Free";
  // Possibly only used by IOS? Set it to 2500-01-01 for non-ios
  // Ios handles cancellation by setting a subscription end date forward whenever we confirm the subscription
  validityDate?: string; // Date of expiration
  hadFreeTrial?: boolean;
  currentProvider?: "ios" | "android" | "stripe" | "gift" | "other";
  purchaseHistory?: PurchaseHistory[];

  constructor(data: Partial<SubscriptionInfo> = {}) {
    this.paymentStatus = data?.paymentStatus || "Free";
    this.validityDate = data?.validityDate;
    this.purchaseHistory = data?.purchaseHistory?.map(
      (ph) => new PurchaseHistory(ph)
    );
  }
}

export class PurchaseHistory {
  dateOfPurchase?: string;
  sku?: "monthlysubscription" | "yearlysubscription";
  pointOfPurchase?: "ios-iap" | "android-iap" | "stripe";
  // for IOS this will be the initial transaction ID
  transactionID?: string;
  status?: "active" | "canceled"; // ios won't ever be cancelled but other systems might be
  // Possibly other fields as needed for each type

  constructor(data: Partial<PurchaseHistory> = {}) {
    this.dateOfPurchase = data?.dateOfPurchase;
    this.sku = data?.sku;
    this.pointOfPurchase = data?.pointOfPurchase;
    this.transactionID = data?.transactionID;
    this.status = data?.status;
  }
}

export class ShareTo {
  // Main Id
  shareId?: string;
  // Uid of User - Set after acct creation
  recipient?: string;
  // Uid of referrer
  referrer?: string;
  // Email shared
  shareEmail?: string;
  // Later could figure out text share
  sharePhone?: string;
  // All other stuff, dates, etc.
  // ... Other stuff
}

// Move to Maps Classing
export type MarkerType =
  | "Access Point"
  | "Bicycle"
  | "Boat Ramp"
  | "Campsite"
  | "Dam"
  | "Fuel"
  | "Information"
  | "Marker"
  | "Mountain"
  | "Parking"
  | "Restaurant"
  | "Hazard"
  | "Snowmobile"
  | "Stream Gage"
  | "Restroom"
  | "Trailhead"
  | "Waterfall"
  | "Business";
