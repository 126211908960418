import "../../styles/loader.scss";

const Loader = ({ loaderText }: any) => {
	return (
		<div className="loader">
			<div className="loader-icon"></div>
			{loaderText && <p>{loaderText}</p>}
		</div>
	);
};

export default Loader;



