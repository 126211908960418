import "../../styles/loader.scss";

const PageLoader = (props: any) => {
	return (
		<div className="loader-backdrop">
			<div className="loader">
				<div className="loader-icon"></div>
				<p>Finding Fish</p>
			</div>
		</div>
	);
};

export default PageLoader;
