import { create } from "zustand";
import { UserState } from "../appState";

const chatApiUrl = `${process.env.REACT_APP_API_URL}/api/chat`;

function getTwilioHeaders() {
  const UserToken = localStorage.getItem("ffftoken");
  const UID = UserState.getState().uid || "";
  return new Headers({
    "content-type": "application/json",
    authorization: `${UserToken}`,
    requestingUID: UID,
  });
}

export class Message {
  accountSid: string;
  attributes: string;
  author: string;
  body: string;
  contentSid: null;
  conversationSid: string;
  dateCreated: string;
  dateUpdated: string;
  delivery: string;
  index: number;
  media: string;
  participantSid: string;
  sid: string;
  url: string;
  constructor(data: Partial<Message> = {}) {
    this.accountSid = data.accountSid || "";
    this.attributes = data.attributes || "";
    this.author = data.author || "";
    this.body = data.body || "";
    this.contentSid = data.contentSid || null;
    this.conversationSid = data.conversationSid || "";
    this.dateCreated = data.dateCreated || "";
    this.dateUpdated = data.dateUpdated || "";
    this.delivery = data.delivery || "";
    this.index = data.index || 0;
    this.media = data.media || "";
    this.participantSid = data.participantSid || "";
    this.sid = data.sid || "";
    this.url = data.url || "";
  }
}

export class Conversation {
  accountSid: string;
  attributes: string;
  chatServiceSid: string;
  conversationSid: string;
  conversationState: string;
  createdBy: string;
  dateCreated: string;
  dateUpdated: string;
  friendlyName: string;
  lastReadMessageIndex: number;
  messages: Message[];
  participants: Participant[];
  constructor(data: Partial<Conversation> = {}) {
    this.accountSid = data.accountSid || "";
    this.attributes = data.attributes || "";
    this.chatServiceSid = data.chatServiceSid || "";
    this.conversationSid = data.conversationSid || "";
    this.conversationState = data.conversationState || "";
    this.createdBy = data.createdBy || "";
    this.dateCreated = data.dateCreated || "";
    this.dateUpdated = data.dateUpdated || "";
    this.friendlyName = data.friendlyName || "";
    this.lastReadMessageIndex = data.lastReadMessageIndex || 0;
    this.messages = data?.messages?.map((mess) => new Message(mess)) || [];
    this.participants =
      data?.participants?.map((part) => new Participant(part)) || [];
  }
}

export class Participant {
  sid: string;
  accountSid: string;
  chatServiceSid: string;
  roleSid: string;
  identity: string;
  friendlyName: string | null;
  attributes: string;
  isOnline: boolean | null;
  isNotifiable: boolean | null;
  dateCreated: string;
  dateUpdated: string;
  url: string;
  links: {
    user_conversations: string;
  };

  constructor(data: Partial<Participant> = {}) {
    this.sid = data.sid || "";
    this.accountSid = data.accountSid || "";
    this.chatServiceSid = data.chatServiceSid || "";
    this.roleSid = data.roleSid || "";
    this.identity = data.identity || "";
    this.friendlyName = data.friendlyName || null;
    this.attributes = data.attributes || "{}";
    this.isOnline = data.isOnline || null;
    this.isNotifiable = data.isNotifiable || null;
    this.dateCreated = data.dateCreated || "";
    this.dateUpdated = data.dateUpdated || "";
    this.url = data.url || "";
    this.links = data.links || { user_conversations: "" };
  }
}

export interface ChatState {
  conversations: any[];
  users: any[];
  focusedConversation: Conversation | null;
  setConversations: (conversations: any[]) => void;
  addConversation: (conversation: any) => void;
  setFocusedConversation: (conversation: Conversation | null) => void;
  createConversation: (conversationName: string, uid: string) => Promise<any>;
  createConversationUser: (uid: any, userName: string) => Promise<any>;
  getUser: (uid: string) => Promise<any>;
  getAllUserConversations: (uid: string) => Promise<any>;
  createConversationMessage: (
    conversationSID: string,
    message: any
  ) => Promise<any>;
  addConversationParticipant: (
    conversationSID: string,
    uid: string
  ) => Promise<any>;
  getConversationParticipants: (conversationSID: string) => Promise<any>;
  getConversationMessages: (conversationSID: string) => Promise<any>;
  setFocusedConversationMessages: (messages: Message[]) => void;
  setFocusedConversationParticipants: (participants: Participant[]) => void;
  setUserToken: (token: string) => void;
}

export const ChatService = create<ChatState>((set) => ({
  conversations: [],
  users: [],
  focusedConversation: null,
  setConversations: (conversations: any[]) =>
    set((state) => ({ conversations: conversations })),
  addConversation: (conversation: any) =>
    set((state) => ({
      conversations: [...(state?.conversations || []), conversation],
    })),
  setFocusedConversation: (conversation: Conversation | null) =>
    set((state) => {
      const focusedState = {
        accountSid: conversation?.accountSid,
        attributes: conversation?.attributes,
        chatServiceSid: conversation?.chatServiceSid,
        conversationSid: conversation?.conversationSid,
        conversationState: conversation?.conversationState,
        createdBy: conversation?.createdBy,
        dateCreated: conversation?.dateCreated,
        dateUpdated: conversation?.dateUpdated,
        friendlyName: conversation?.friendlyName,
        lastReadMessageIndex: conversation?.lastReadMessageIndex,
        messages: conversation?.messages,
      };
      state.focusedConversation = new Conversation(focusedState);
      return state;
    }),
  createConversation: async (conversationName: string, uid: string) => {
    return fetch(`${chatApiUrl}/createConversation`, {
      method: "POST",
      headers: getTwilioHeaders(),
      body: JSON.stringify({
        conversationName: conversationName,
        uid: uid,
      }),
    }).then((res) => res.json());
  },
  createConversationUser: async (uid: any, userName: string) => {
    return fetch(`${chatApiUrl}/createConversationUser`, {
      method: "POST",
      headers: getTwilioHeaders(),
      body: JSON.stringify({
        uid: uid,
        userName: userName,
      }),
    }).then((res) => res.json());
  },
  getUser: async (uid: string) => {
    return fetch(`${chatApiUrl}/getUser`, {
      method: "POST",
      headers: getTwilioHeaders(),
      body: JSON.stringify({
        uid: uid,
      }),
    }).then((res) => res.json());
  },

  // Get all conversations
  getAllUserConversations: async (uid: string) => {
    return fetch(`${chatApiUrl}/getUserConversations`, {
      method: "POST",
      headers: getTwilioHeaders(),
      body: JSON.stringify({
        uid: uid,
      }),
    }).then((res) => res.json());
  },

  //
  createConversationMessage: async (conversationSID: string, message: any) => {
    return fetch(`${chatApiUrl}/createConversationMessage`, {
      method: "POST",
      headers: getTwilioHeaders(),
      body: JSON.stringify({
        conversationSID: conversationSID,
        message: message,
      }),
    }).then((res) => res.json());
  },
  getConversationMessages: async (conversationSID: string) => {
    return fetch(`${chatApiUrl}/getConversationMessages`, {
      method: "POST",
      headers: getTwilioHeaders(),
      body: JSON.stringify({
        conversationSID: conversationSID,
      }),
    }).then((res) => {
      console.log("MESS", res);
      return res.json();
    });
  },
  setFocusedConversationMessages: (messages: any) =>
    set((state: any) => ({
      focusedConversation: {
        ...state.focusedConversation,
        messages: messages.map((mess: any) => new Message(mess)),
      },
    })),
  setFocusedConversationParticipants: (participants: any = []) =>
    set((state: any) => ({
      focusedConversation: {
        ...state.focusedConversation,
        participants: participants.map(
          (participant: any) => new Participant(participant)
        ),
      },
    })),
  setUserToken: (uid: string) => {
    fetch(`${chatApiUrl}/getTwilioToken`, {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({
        uid: uid,
      }),
    }).then((res: any) => {
      new Response(res.body)
        .text()
        .then((text) => localStorage.setItem("twilioToken", text));
      //
    });
  },
  addConversationParticipant: async (conversationSID: string, uid: string) => {
    return new Promise(async (resolve, reject) => {
      fetch(`${chatApiUrl}/addConversationParticipant`, {
        method: "POST",
        headers: getTwilioHeaders(),
        body: JSON.stringify({
          conversationSID: conversationSID,
          uid: uid,
        }),
      })
        .then((res) => resolve(res.json()))
        .catch((err) => console.log(err));
    });
  },
  getConversationParticipants: async (conversationSID: string) => {
    return new Promise(async (resolve, reject) => {
      fetch(`${chatApiUrl}/getConversationParticipants`, {
        method: "POST",
        headers: getTwilioHeaders(),
        body: JSON.stringify({
          conversationSID: conversationSID,
        }),
      }).then((res) => {
        resolve(res.json());
      });
    });
  },
}));

export default ChatService;
