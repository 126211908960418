import React, { createContext, useState, useContext, ReactNode } from "react";

type Notification = {
  id: number;
  message: string;
  type: "info" | "success" | "warning" | "error";
};

type NotificationContextType = {
  notifications: Notification[];
  addNotification: (
    message: string,
    type?: "info" | "success" | "warning" | "error"
  ) => void;
  removeNotification: (id: number) => void;
};

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

let addNotificationRef: (
  message: string,
  type?: "info" | "success" | "warning" | "error"
) => void;

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (
    message: string,
    type: "info" | "success" | "warning" | "error" = "info"
  ) => {
    const id = new Date().getTime();
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id, message, type },
    ]);
    setTimeout(() => removeNotification(id), 4000);
  };

  addNotificationRef = addNotification;

  const removeNotification = (id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

export const getAddNotification = () => {
  if (!addNotificationRef) {
    throw new Error("Notification context is not initialized");
  }
  return addNotificationRef;
};
