import "../../../styles/chat.scss";

interface MessageProps {
  owned: boolean;
  body: string;
  dateStamp: string;
}

const Message = (props: MessageProps) => {
  return (
    <div className={`conversation-message${props?.owned ? " owned" : ""}`}>
      <div className="message-heading">
        <p className="message-author">{props.dateStamp}</p>
      </div>
      <div className="message-card">
        <p className="message-body">{props.body}</p>
      </div>
    </div>
  );
};

export default Message;
